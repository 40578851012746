import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        autoplay: true,
        fixedHeight: "579px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: false,
        arrows: banner.dataset.slides > 1 ? true : false,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
            },
        },
    });
    bannerSplide.mount();
}

const clients = document.getElementById("clients");
if (clients) {
    const clientSplide = splide(clients, {
        perPage: 4,
        arrows: true,
        breakpoints: {
            1200: {
                perPage: 3,
                pagination: true,
            },
            600: {
                perPage: 1,
                pagination: true,
            },
        },
    });
    clientSplide.mount();
}

const company = document.getElementById("company");
if (company) {
    const companySplide = splide(company, {
        perPage: 1,
        breakpoints: {},
    });
    companySplide.mount();
}
